import React, { useRef, useState } from "react";
import { IoIosPlay } from "react-icons/io";
import styled from "styled-components";

import IconButton from "@/components/buttons/IconButton/IconButton";
import Tag from "@/components/buttons/Tag/Tag";
import { H3, P } from "@/components/typography";

import { VideoPropsType } from "./VideoItem.types";

const StyledFigure = styled.figure`
  background: #b8b8b8;
  overflow: hidden;
  position: relative;
  margin: 0;
  display: flex;

  video {
    width: 100%;
    cursor: pointer;
  }

  figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 32px;
    color: white;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0,
      rgba(0, 0, 0, 0.7) 100%
    );

    h3,
    h4,
    h5,
    h6,
    p {
      color: inherit;
      text-align: left;
    }
  }

  @media (max-width: 900px) {
    .video-title {
      font-size: 26px !important;
    }

    .video-subtitle {
      font-size: 16px;
    }
  }
`;

const PlayIconWrapper = styled.div`
  position: absolute;
  top: calc(50% - 90px);
  left: calc(50% - 90px);
  transform: translate(calc(-50% + 90px), calc(-50% + 90px));
  cursor: pointer;
  z-index: 2;
  opacity: 0.7;
`;

const placeholderUrl =
  "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";

export default function VideoItem({
  title,
  subtitle,
  poster,
  src = placeholderUrl,
  className,
  autoPlay,
  tags = [],
  categories = [],
  isHomepageVideo,
}: VideoPropsType) {
  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlayPause = () => {
    const player = videoRef.current;

    if (player && !isPlaying) {
      player.play();
      setIsPlaying(true);
    } else if (player) {
      player.pause();
      setIsPlaying(false);
    }
  };

  const stopVideo = () => {
    const player = videoRef.current;
    if (isPlaying && player) {
      player.pause();
      setIsPlaying(() => false);
    }
  };

  return (
    <StyledFigure className={className}>
      <video
        src={src}
        ref={videoRef}
        controls={isPlaying}
        onClick={stopVideo}
        poster={poster}
        onMouseEnter={autoPlay ? handlePlayPause : () => {}}
        onMouseLeave={autoPlay ? stopVideo : () => {}}
        muted={true}
        className={isHomepageVideo ? "max-h-624px object-cover" : ""}
      ></video>
      {!isPlaying && (
        <>
          <PlayIconWrapper onClick={handlePlayPause}>
            <IconButton Icon={IoIosPlay} />
          </PlayIconWrapper>
          <figcaption>
            <div className={"video-tags flex flex-wrap gap-2"}>
              {categories &&
                categories.map((c) => {
                  return (
                    <Tag
                      key={c}
                      variant="dark"
                      size="m"
                      style={{
                        backgroundColor: "#3FC7B4",
                        borderColor: "#3FC7B4",
                      }}
                    >
                      {c}
                    </Tag>
                  );
                })}
            </div>
            {!!title && <H3 className="video-title">{title}</H3>}
            {!!subtitle && <P className="video-subtitle">{subtitle}</P>}
          </figcaption>
        </>
      )}
    </StyledFigure>
  );
}
