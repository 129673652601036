import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";

import { InstaPost } from "@/components/content-types/InstagramPost/InstagramPost";
import ContentItem from "@/components/layout/MasonryGrid/ContentItem";
import { AssignmentPostType } from "@/types/assignmentTypes";
import { BlogType } from "@/types/content-types/Blog.type";
import { Letter } from "@/types/content-types/Letter.type";
import { SpotifyPostType } from "@/types/content-types/SpotifyPost.type";
import { VideoType } from "@/types/content-types/Video.type";
import { ForumPostType } from "@/types/forumTypes";

import { MasonryGridWrapper } from "./MasonryGrid.styled";

export type PageBlockItem = {
  audio_item: any;
  blog_quote: BlogType[];
  chats_item: any;
  forum: ForumPostType[];
  instagram: InstaPost[];
  letter: Letter[];
  spotify_item: SpotifyPostType[];
  total_post_count: number;
  videos: VideoType[];
  assignments: AssignmentPostType[];
};

type Props = {
  pageBlocksArr: PageBlockItem[];
};

export function MasonryGrid({ pageBlocksArr }: Props) {
  const [loading, setLoading] = useState(true);
  let pageBlockCounter = 0;

  useEffect(() => {
    if (pageBlocksArr.length > 0) {
      setLoading(false);
    }
  }, [pageBlocksArr]);

  const pushItem = (
    type: string,
    items: any[],
    index: number,
    contentItems: any[],
  ) => {
    if (items.length > index) {
      const exists = contentItems.find(
        (item) => item.type === type && item.item.id === items[index].id,
      );
      if (!exists) {
        contentItems.push({ type, item: items[index] });
      }
    }
  };

  const getContentItemsForPageBlock = (pageBlock: PageBlockItem) => {
    const contentItems: any[] = [];

    // Do this twice so that a possible second fetch in index is also rendered properly
    for (let i = 0; i < 2; i++) {
      pushItem("videos", pageBlock.videos, i, contentItems);
      pushItem("forum", pageBlock.forum, i, contentItems);
      pushItem("instagram", pageBlock.instagram, i, contentItems);
      pushItem("letter", pageBlock.letter, i, contentItems);
      pushItem("chats_item", pageBlock.chats_item, i, contentItems);
      pushItem("blog_quote", pageBlock.blog_quote, i, contentItems);
      pushItem("audio_item", pageBlock.audio_item, i, contentItems);
      pushItem("assignments", pageBlock.assignments, i, contentItems);
    }

    return contentItems;
  };

  if (loading) {
    return (
      <MasonryGridWrapper>
        <Container>
          <p>Laden...</p>
        </Container>
      </MasonryGridWrapper>
    );
  }

  return (
    <MasonryGridWrapper className={"mb-[0px]"}>
      <Container className="max-w-[1384px] px-[16px] md:px-[32px]">
        {pageBlocksArr.map((pageBlock) => {
          const contentItems = getContentItemsForPageBlock(pageBlock);

          return (
            <div
              className={`mason-grid-custom-size mason-grid-${contentItems.length}`}
              key={pageBlockCounter}
            >
              {contentItems.map((contentItem) => (
                <ContentItem data={contentItem} key={contentItem.item.id} />
              ))}
            </div>
          );
        })}
      </Container>
    </MasonryGridWrapper>
  );
}
