import React from "react";

import SpotifyPost from "@/components/content-types/SpotifyPost/SpotifyPost";
import { Audio } from "@/types/content-types/Audio.type";

export default function AudioItem({
  audio_link,
  spotify_link,
  spotify_full_screen = false,
  categories,
}: Audio) {
  if (spotify_full_screen) {
    return <SpotifyPost songLink={spotify_link} categories={categories} />;
  } else {
    return null;
  }
}
