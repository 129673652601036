import Image from "next/image";
import React, { useState } from "react";

import Button from "@/components/buttons/Button";
import Tag from "@/components/buttons/Tag/Tag";
/* eslint-disable @next/next/no-img-element */
import Card from "@/components/card/Card";
import CardFooter from "@/components/card/CardFooter/CardFooter";
import CardHeader from "@/components/card/CardHeader/CardHeader";
import { P, TitleWithHighlights } from "@/components/typography";
import { truncate } from "@/utils/truncate";

type Props = {
  btnHidden?: boolean;
  title: string;
  titleHighlighted?: string;
  imgSrc: string;
  fileSrc: string;
  categories?: string[];
  content: string;
  bg?: string;
  imgHeight?: number;
  className?: string;
  buttonColor?: string;
  tags?: string[];
  buttonText?: string;
  redCategories?: string[];
  redTags?: string[];
};

export default function BriefItem({
  imgSrc,
  fileSrc,
  title,
  titleHighlighted,
  className,
  btnHidden,
  categories,
  content,
  imgHeight = 180,
  bg = `#3FC7B4`,
  buttonColor = "#3FC7B4",
  buttonText = "Lees verder",
  tags = [],
  redTags = [],
  redCategories = [],
}: Props) {
  const [hovering, setHovering] = useState<boolean>(false);

  const renderTags = (
    color: string,
    rCategories: string[],
    rTags: string[],
  ) => {
    return (
      <div className={"blog-tags"}>
        {rCategories &&
          rCategories.map((c) => {
            return (
              <Tag
                key={c}
                variant="dark"
                size="m"
                style={{
                  backgroundColor: color,
                  borderColor: color,
                  display: "inline-block",
                }}
              >
                <>{c}</>
              </Tag>
            );
          })}

        {rTags &&
          rTags.map((tag) => {
            return (
              <Tag
                key={tag}
                variant="light"
                size="m"
                style={{
                  color,
                  backgroundColor: "white",
                  borderColor: "white",
                  display: "inline-block",
                }}
              >
                <>{tag}</>
              </Tag>
            );
          })}
      </div>
    );
  };

  return (
    <Card variant="brief" className={className}>
      <CardHeader style={{ maxHeight: imgHeight, minHeight: imgHeight }}>
        <>
          <Image
            priority
            className="absolute left-0 top-0 z-0 h-full w-full object-cover"
            src={imgSrc}
            alt={title}
            fill
            sizes={
              "(max-width: 786px) 100vw, (max-width: 1024px) 45vw,  (max-width: 1200px) 30vw, 25vw"
            }
          />
          {renderTags("#3FC7B4", categories ?? [], tags)}
          {renderTags("#FE517E", redCategories ?? [], redTags)}
        </>
      </CardHeader>
      <CardFooter
        className={`group bg-[${bg}]  p-[24px] md:px-[24px] md:pb-[24px] md:pt-[16px]`}
      >
        <div>
          <div className="title-with-image">
            <TitleWithHighlights
              color="black"
              highlightColor="tertiary"
              text={`📖 ${title}`}
              headerElement="h3"
              className="text-[30px]  transition md:text-[32px]"
              style={{ fontWeight: "400" }}
            />
          </div>
          <P
            className="transition"
            style={{
              maxHeight: 200,
              marginTop: 12,
              fontWeight: "300",
              overflow: "hidden",
            }}
          >
            {truncate(content, 500)}
          </P>
        </div>
        {!btnHidden && (
          <Button
            href={fileSrc}
            hover={hovering}
            style={{
              background: buttonColor,
              fontWeight: `400`,
              fontSize: `18px`,
            }}
            // variant="tertiary"
          >
            {buttonText}
          </Button>
        )}
      </CardFooter>
    </Card>
  );
}
